import React from 'react'

import {
  Col,
  DateInput,
  Detail,
  FormGroup,
  Input,
  Row,
  Spacer
} from '@te-digi/styleguide'

const InputDetailExample = () => (
  <>
    <Row>
      <Col>
        <FormGroup>
          <Detail heading="Detail heading">
            <Spacer marginTop="md">Value</Spacer>
          </Detail>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Input
            label="Label"
            onChange={() => {}}
            onBlur={() => {}}
            value="Value"
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <Detail
            heading="Detail heading"
            subHeading="Detail subheading"
          >
            <Spacer marginTop="md">Value</Spacer>
          </Detail>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Input
            label="Label"
            onChange={() => {}}
            onBlur={() => {}}
            subLabel="Sub Label"
            value="Value"
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <Detail heading="Aloituspäivä">1.1.2000</Detail>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <DateInput
            label="Päättymispäivä"
            subLabel="pp.kk.vvvv"
            onChange={() => {}}
            value=""
          />
        </FormGroup>
      </Col>
    </Row>
  </>
)

export { InputDetailExample }
