import React from 'react'

import { Detail } from '@te-digi/styleguide'

const DetailExample1 = () => (
  <Detail heading="Label of the input field">Value of the input field</Detail>
)

const DetailWithoutChildrenExample = () => (
  <Detail heading="Detail without value" />
)

const DetailWithSublabelExample = () => (
  <Detail
    heading="Label of the field"
    subHeading="Sublabel of the field"
  >
    Value
  </Detail>
)

export {
  DetailExample1,
  DetailWithoutChildrenExample,
  DetailWithSublabelExample
}
