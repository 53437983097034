import React from 'react'

import { Detail, LocationIcon } from '@te-digi/styleguide'

const DetailExample2 = () => (
  <Detail
    heading="Sijainti"
    icon={<LocationIcon />}
  >
    Joustava tai useita sijainteja
  </Detail>
)

export { DetailExample2 }
