import React from 'react'

import { Detail, Paragraph } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import {
  DetailExample1,
  DetailWithoutChildrenExample,
  DetailWithSublabelExample
} from '../../examples/react/DetailExample1'
import { DetailExample2 } from '../../examples/react/DetailExample2'
import { InputDetailExample } from '../../examples/react/InputDetailExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Detail"
    components={[{ component: Detail }]}
    status={[{ type: 'accessible', version: '13.10.0' }]}
  >
    <Section>
      <Playground>{DetailExample1()}</Playground>
      <Playground>{DetailWithoutChildrenExample()}</Playground>
    </Section>
    <Section title="Ikonilla">
      <Playground>{DetailExample2()}</Playground>
    </Section>
    <Section title="Lomakkeen read-only-kenttien esittäminen">
      <Playground>{DetailWithSublabelExample()}</Playground>
      <Playground
        example={InputDetailExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Saavutettavuus">
      <Paragraph>
        <Code>Detail</Code> on semanttisesti tekstisisältöä.{' '}
        <Code>Heading</Code>
        -propin arvo tulostetaan html:n <Code>strong</Code>-elementtinä. Sen saa
        halutessaan korostettua ruudunlukijalla, mutta oletusarvoisesti{' '}
        <Code>strong</Code> ei korostu tekstistä.
      </Paragraph>
      <Paragraph>
        <Code>Detail</Code>iin ei ohjata fokusta. Lomakkeilla suositellaan
        käyttämään <Code>Detail</Code>-komponettia ei-muokattavan tiedon
        esittämiseen read-only-tilaisen input-kentän sijaan.
        Read-only-inputkentät ovat ongelmallisia: lomakkeen kenttiä tabilla
        selatessa myös read-only-kentät luetaan ruudunlukijalla, ja vasta
        viimeisenä kentästä ilmoitetaan, että sisältöä ei voi muokata.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
